import { createReducer, on, State } from '@ngrx/store';

import * as ConfigActions from './config.actions';

export interface Config {
  env: string;
  vhost: string;
  logger: string;
  partner_uuid: string;
  samls: any[];
  core_api: string;
  skin: string;
  magiclink: string;
  signup: string;
  s3_api: string;
  payment_mode: 'manual_activation' | 'auto_activation' | 'billing';
}

export const configFeatureKey = 'config';

export const initialState: Partial<Config> = {};

export const configReducer = createReducer(
  initialState,
  on(ConfigActions.saveConfig, (state, action) => ({
    ...state,
    ...action.data
  }))
);
