import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash';
import * as fromConfig from './config.reducer';

export const selectConfigState = createFeatureSelector(fromConfig.configFeatureKey);

export const getConfig = createSelector(selectConfigState, state => state);


export const getConfigVhost = createSelector(selectConfigState, (state) => get(state, ['vhost'], false));
export const getConfigEnv = createSelector(selectConfigState, (state) => get(state, ['env'], 'none'));
export const isProductionEnv = createSelector(selectConfigState, (state) => get(state, ['env'], 'none') == 'production');
export const getSkin = createSelector(selectConfigState, (state) => get(state, ['skin'], 'none'));
export const isGsSkin = createSelector(selectConfigState, (state) => get(state, ['skin'], 'none') == 'gs');
export const isNotGsSkin = createSelector(selectConfigState, (state) => get(state, ['skin'], 'none') != 'gs');
export const getCoreApiEndpint = createSelector(selectConfigState, (state) => get(state, ['core_api'], false));
export const getPaymentMode = createSelector(selectConfigState, (state) => get(state, ['payment_mode'], false));
export const getNPSEndpoint = createSelector(selectConfigState, getConfigEnv, (state, env) => {
    if(env && env !== "production") {
      return "https://nps.gridscale.dev/";
    } else {
      return "https://nps.ludicrously-happy.io/";

    }
})

